* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
  color: #0f172a;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  padding: 48px 64px 0px;
}

.hidden {
  display: none !important;
}

.message {
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  margin-top: 24px;
}

.disputed {
  color: #ef4444;
  font-weight: 600;
  margin-right: 10px;
}

/* MAIN LAYOUT */
#root {
  max-width: 1500px;
  margin: 0 auto;
}

.header {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 48px;

  height: calc(100vh - 48px - 68px - 40px);
}

@media (max-width: 900px) {
  .main {
    grid-template-columns: 1fr;

    height: auto;
  }
}

section {
  overflow: scroll;
  padding-bottom: 40px;
}

/* Elements */

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logo img {
  width: 72px;
  height: 72px;
}

h1 {
  font-size: 42px;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  line-height: 1;
  margin-top: 6px;
}

.category {
  margin-bottom: 16px;
}

.comment {
  font-size: 20px;
  line-height: 1.4;
  background-color: #cbd5e1;
  margin-bottom: 16px;
  padding: 16px 24px;
  letter-spacing: -1px;
  border-radius: 16px;

  display: flex;
  align-items: center;
  gap: 24px;
}

@media (max-width: 1200px) {
  .comment {
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
  }
}

.tag {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 100px;
}

.vote-buttons {
  margin-left: auto;
  flex-shrink: 0;

  display: flex;
  gap: 8px;
}

.vote-buttons button {
  border: none;
  background-color: #e2e8f0;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 100px;
  color: inherit;
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.vote-buttons button:hover {
  background-color: #cbd5e1;
}

ul {
  list-style: none;
}

.handle:link,
.handle:visited {
  color: #4b5563;
  text-decoration: none;
  margin-left: 12px;
  transition: 0.3s;
}

.handle:hover,
.handle:active {
  color: #3b82f6;
}

.btn {
  border: none;
  font-family: "Raleway", sans-serif;
  line-height: 1;
  text-transform: uppercase;
  font-size: 17px;
  padding: 16px 0px 13px;
  background-image: linear-gradient(135deg, #e2e8f0, #f9fafb, #f8fafc, #e2e8f0);
  color: inherit;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.3s;
}

.btn:hover {
  transform: scale(110%) rotate(-2deg);
}

.btn-large {
  font-size: 16px;
  padding: 18px 32px 18px;
}

.btn-all-categories {
  margin-bottom: 16px;
  width: 100%;
}

.btn-category {
  width: 100%;
  background-image: none;
}

/* Form */

.comment-form {
  background-color: #cbd5e1;
  margin-bottom: 40px;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
}

.comment-form input,
.comment-form select {
  width: 220px;
  background-color: #e2e8f0;
  border: none;
  border-radius: 100px;
  padding: 16px;
  font-size: 14px;
  color: inherit;
  font-family: inherit;
}

.comment-form input::placeholder {
  color: #4b5563;
}

.comment-form input:first-child {
  flex-grow: 1;
}

.comment- .comment-form span {
  font-weight: 600;
  font-size: 14px;
  margin-right: 18px;
}

@media (max-width: 1000px) {
  .comment-form {
    flex-direction: column;
    align-items: stretch;
  }

  .comment-form input,
  .comment-form select {
    width: auto;
  }
}

@media (max-width: 900px) {
  .main {
    grid-template-columns: 1fr;
    height: auto;
  }
}

@media (max-width: 600px) {
  #root {
    padding: 24px;
  }

  .header {
    flex-direction: column;
    gap: 16px;
  }

  .comment-form {
    padding: 16px;
  }

  .comment {
    padding: 12px 16px;
  }
}
